import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const getGeneralBalance = async (start, end) => {
    return apiClient.get(`${apiBaseUrl}/collection/portfolio/balance/${start}/${end}`)
        .then(response => { return response })
}

export const getPortfolioClients = async (start, end, page = 1, limit = 10, search = "", filters = "", sort = "") => {
    const escapedSearch = encodeURIComponent(search);
    var wildcard = escapedSearch != "null" ? "wildCard~" + escapedSearch : ""
    return apiClient.get(`${apiBaseUrl}/collection/portfolio/clients/${start}/${end}?page=${page}&limit=${limit}&q=${wildcard}${filters || ''}&${sort || 'field=created&order=-1'}`)
        .then(response => { return response })
}

export const getAditionalServices = async (accountId) => {
    return apiClient.get(`${apiBaseUrl}/collection/service/${accountId}`, {})
        .then(response => { return response })
}

export const reportServicePayment = async (serviceId, date, amount) => {
    return apiClient.put(`${apiBaseUrl}/taxpayer-account/service/${serviceId}`, {
        "payment_date": date,
        "payment_amount": amount
    })
        .then(response => { return response })
}

